import { OptionalLink, OptionalLinkProps } from '@/components/OptionalLink';
import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { ReactNode } from 'react';
import { Tag } from './Tag';

export type StandaloneTagProps = StandaloneComponentProps<typeof Tag> & {
  value?: ReactNode;
  link?: OptionalLinkProps;
};

export const StandaloneTag: StandaloneComponent<StandaloneTagProps> = ({ value, link, ...props }) => {
  return (
    <Tag {...props}>
      <OptionalLink {...link}>{value}</OptionalLink>
    </Tag>
  );
};
